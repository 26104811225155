/*=========================================================================================
  File Name: moduleActivityState.js
  Description: Activity Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  medicalPackages:[],
  medicalPackageSearchObj: {
    DateFrom: null,
    DateTo: null,
    Keyword: null,
    ValidDate: null,
  }
}
